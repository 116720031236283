const DropDownList = [
  {
    id: "ODCinfo",
    name: "MS-7505",
  },
  {
    id: "mcODCinfo",
    name: "MC FBU3650U",
  },
  {
    id: "odcai_track2",
    name: "MAN XE-5012-Y",
  },
  {
    id: "odcai_track3",
    name: "MS YR-1069-E",
  },
  {
    id: "rfid_track",
    name: "BIN YN-7015-S",
  },
  {
    id: "YN5609S",
    name: "MS YN-5609-S",
  },
  {
    id: "YN5724S",
    name: "MS YN-5724-S",
  },
  {
    id: "YP7537S",
    name: "MS YP-7537-S",
  },
  {
    id: "YN473Z",
    name: "MS YN-473-Z",
  },
  {
    id: "YN5942D",
    name: "MS YN-5942-D",
  },
  {
    id: "YP7542B",
    name: "MS YP-7542-B",
  },
  {
    id: "YN6458D",
    name: "MS YN-6458-D",
  },
  /* {
        id:'dicSensor', 
        name: 'DIC SENSOR'
    },
    {
        id:'bin', 
        name: 'Bin'
    }, */
  // {
  //     id:'smartCombi',
  //     name: 'SMART COMBI ARM'
  // }
];

//mark as false positive

export default DropDownList;
